<template>
  <div class="shadow-lg p-3 mb-5 mt-5 container">
    <h1>Schützenbuch <img
        src="/Bilder/book.png"
        width="100"
        height="100"
        class="rounded-circle"/></h1>

    <br>
    <h2> Hallo {{ getUserName }}</h2>
    <br>
    <dashboard
        v-if="!comp_OpenShootingBookData_Club_DefaultClub & this.comp_OpenShootingBookData_CountOwnWeapons"></dashboard>
    <br>
<!--    <v-card-->
<!--        v-if="comp_show_NewUser"-->
<!--        elevation="5"-->
<!--        class="mt-4 mb-4"-->
<!--    >-->
<!--      <v-card-text>-->
<!--        <div-->
<!--            class="text&#45;&#45;primary"-->
<!--            v-html="getTextNewUser">-->
<!--        </div>-->
<!--      <v-alert-->
<!--          v-show="!comp_OpenShootingBookData_CountOwnWeapons"-->
<!--          class="mt-3"-->
<!--          type="info">-->
<!--        Du hast noch keine Waffe hinterlegt, bitte gehe auf <a href="/user/weapon"> Meine Waffen </a>-->
<!--        und füge zuerst deine Waffen hinzu!-->
<!--      </v-alert>-->
<!--      <v-alert-->
<!--          v-show="comp_OpenShootingBookData_Club_DefaultClub"-->
<!--          type="info">-->
<!--        Du hast keinen Standardverein festgelegt, bitte stelle auf deinem <a href="/user/profil"> Profil </a>-->
<!--        zuerst deinen Verein ein.-->
<!--      </v-alert>-->
<!--      </v-card-text>-->
<!--    </v-card>-->
    <div class="form-group row">
      <div
          class="col-sm-10 mb-5 pt-3"
          v-html="getUserWelcomeText">
      </div>
    </div>


  </div>
</template>

<script>
import dashboard from '@/views/component/dashboard';
export default {
  components: {
    dashboard
  },
  name: "uwelcome",
  data: () => ({}),
  computed: {
    getUserWelcomeText() {
      return this.$store.state.UserApp.TextUwelcome;
    },
    getTextNewUser() {
      return this.$store.state.UserApp.TextNewUser;
    },
    getUserName() {
      return this.$store.getters['UserAuth/isUserName'];
    },
    comp_OpenShootingBookData_CountOwnWeapons() {
      const state = this.$store.state.UserApp.Weapons;
      var data = false;
      if(state.length > 0) {
        data =  true;
      }
      return data;
    },
    comp_OpenShootingBookData_Club_DefaultClub() {
      const state = this.$store.state.UserApp.OpenShootingBookData;
      var data = false;
      if(state != null && Object.keys(state).length > 0) {
        data =  state.Club.Vereinsnummer === '99999';
      }
      return data;
    },

    comp_show_NewUser() {
      var data = false;
      const state = this.$store.state.UserApp.Weapons;
      if(Object.keys(state).length > 0) {
        data = !this.comp_OpenShootingBookData_CountOwnWeapons || this.comp_OpenShootingBookData_Club_DefaultClub;
      }
      return data;
    },

  },

}
</script>

<style scoped>

</style>
